import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Dropdown, Menu, Select, Popconfirm, Row, Col, Collapse, Table, message } from 'antd';
import { fetchComponentInitialData, fetchComponentCategoryCreateUpdateData, DeleteWorkflowComponent, DeleteWorkflowCategory, UpdateWorkflowCategory, fetchWorkFlowData, UpdateWorkflowComponent, saveWorkflowComponent } from '../../../services/componentService';
import { EditOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;
const masterUrlQuery = "?config=master";
const hideCategories = ['Workflow Event'];

const WorkFlowComponent = () => {
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [masterOptions, setMasterOptions] = useState([]);
  const [componentData, setComponentData] = useState(null);
  const [workflowData, setWorkflowData] = useState({});
  const [isCollapseVisible, setIsCollapseVisible] = useState(true);
  const [isWorkflowCategoriesVisible, setIsWorkflowCategoriesVisible] = useState(true);
  const [isComponentCreationVisible, setIsComponentCreationVisible] = useState(false);
  const [selectedCategory, setselectedCategory] = useState('');
  const [selectedMasterComp, setselectedMasterComp] = useState('');
  const [userCompName, setuserCompName] = useState('');
  const [compConfig, setcompConfig] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [editRecordKey, setEditRecordKey] = useState(null);

  useEffect(() => {
    // Fetch category and master data from the API
    fetchComponentInitialData().then(response => {
      const { category, masterComponent } = response.data;

      // Remove duplicates based on category_name
      const uniqueCategories = category.reduce((acc, current) => {
        const existingCategory = acc.find(item => item.category_name === current.category_name);
        if (!existingCategory) {
          acc.push(current);
        }
        return acc;
      }, []);

      setCategoryOptions(
        uniqueCategories.map(cat => ({
          value: cat.category_id,
          label: cat.category_display_name,
        }))

      );

      setMasterOptions(
        masterComponent.map(master => ({
          value: master.component_id,
          label: master.component_display_name,
          projectPath: master.project_path,
        }))
      );
    }).catch(error => {
      console.error('Failed to fetch initial data:', error);
    });

    // Fetch workflow data
    fetchWorkFlowData().then(response => {
      const data = JSON.parse(response.data.component);
      const { Categories, Components } = data;
      const categories = {};

      Categories.forEach(cat => {
        categories[cat.displayName] = Components.Component[cat.displayName] || [];
      });
      setWorkflowData(categories);
    }).catch(error => {
      console.error('Failed to fetch workflow data:', error);
    });
  }, []);


  const handleAddNewClick = ({ key }) => {
    if (key === 'addCategory') {
      setIsCategoryModalVisible(true);
      setIsComponentVisible(false);
      setIsWorkflowCategoriesVisible(true);
      setIsComponentCreationVisible(false); // Hide component creation form
    } else if (key === 'addComponent') {
      setIsComponentVisible(true);
      setIsCategoryModalVisible(false);
      setIsWorkflowCategoriesVisible(false);
      setIsComponentCreationVisible(true); // Show component creation form
    }
  };
  const handleCategoryOk = () => {
    form.validateFields()
      .then(values => {
        const newCategory = {
          categoryId: isEditingCategory ? values.categoryId : null,
          categoryName: values.componentCategoryName,
        };

        fetchComponentCategoryCreateUpdateData(newCategory.categoryId, newCategory.categoryName)
          .then(response => {
            // Check if the category name already exists
            if (newCategory.categoryId === null && response.data && response.categoryId && response.data.isExists === 'true') {
              message.error('Category name already exists. Please choose a different name.');
            } else {
              message.success(isEditingCategory ? 'Category updated successfully.' : 'Category added successfully.');
              window.location.reload();

              // Update category options and reset
              setCategoryOptions(prevOptions => {
                if (isEditingCategory) {
                  return prevOptions.map(option =>
                    option.value === newCategory.categoryId
                      ? { value: newCategory.categoryId, label: newCategory.categoryName }
                      : option
                  );
                } else {
                  return [
                    ...prevOptions,
                    { value: response.data.newCategoryId, label: newCategory.categoryName }
                  ];
                }
              });
              setIsCategoryModalVisible(false);
              form.resetFields();
              setIsWorkflowCategoriesVisible(true);
              setIsEditingCategory(false);
            }
          })
          .catch(error => {
            console.error('Failed to add/update category:', error);
            message.error('Failed to add/update category. Please try again.');
          });
      })
      .catch(error => {
        console.error('Failed to validate form fields:', error);
        message.error('Please fill in all required fields.');
      });
  };


  const handleConfigClick = (values) => {
    const selectedMaster = masterOptions.find(
      master => master.value === values.master
    );
    setComponentData({
      ...values,
      label: selectedMaster ? selectedMaster.label : '',
      projectPath: selectedMaster ? selectedMaster.projectPath + masterUrlQuery + "&compId=" + editRecordKey : '',
    });
    setIsPreviewModalVisible(true);
    refreshWorkflowData();

  };

  const handleCreateComponent = (values) => {
    const selectedMaster = masterOptions.find(
      master => master.value === values.master
    );
    setComponentData({
      ...values,
      label: selectedMaster ? selectedMaster.label : '',
      projectPath: selectedMaster ? selectedMaster.projectPath + masterUrlQuery + "&compId=" + editRecordKey : '',
    });
    setIsPreviewModalVisible(true);
  };
  const refreshWorkflowData = async () => {
    try {
      const updatedData = await fetchWorkFlowData();
      const data = JSON.parse(updatedData.data.component);
      const { Categories, Components } = data;
      const categories = {};

      Categories.forEach(cat => {
        categories[cat.displayName] = Components.Component[cat.displayName] || [];
      });
      setWorkflowData(categories);
    } catch (error) {
      console.error('Failed to refresh workflow data:', error);
    }
  };
  const handleSave = async () => {
    try {


      const formData = {
        componentId: isEditing ? editRecordKey : null,
        categoryId: selectedCategory,
        masterComponentId: selectedMasterComp,
        componentName: userCompName,
        componentConfig: JSON.stringify(compConfig),
      };



      const response = await saveWorkflowComponent(formData);

      if (response) {

        if (formData.componentId === null && response.data && response.data.isExists === 'true') {
          message.error("Component name already exists. Please choose a different name.");
          return;
        }
        if (isEditing && response.success) {
          message.success("Component updated successfully");
          window.location.reload();
        } else if (!isEditing && response.success) {
          message.success("Component created successfully");
          window.location.reload();
        } else {
          message.error(response.error || "There was an error while saving the component");
        }
        refreshWorkflowData();
      } else {
        message.error("There was no response from the server");
      }


      // Refresh the workflow data
      const updatedData = await fetchWorkFlowData();
      const data = JSON.parse(updatedData.data.component);
      const { Categories, Components } = data;
      const categories = {};

      Categories.forEach(cat => {
        categories[cat.componentName] = Components.Component[cat.componentName] || [];
      });
      setWorkflowData(categories);

      // Close modal and reset form
      setIsComponentVisible(false);
      setIsPreviewModalVisible(false);
      form.resetFields();

    } catch (error) {
      console.error('Error saving component:', error);
      message.error("There was an error while saving");
    }
  };


  const changeCategory = (value) => {
    setselectedCategory(value);
  };

  const changeMasterComp = (value) => {
    setselectedMasterComp(value);
  };

  const setComponentName = (value) => {
    setuserCompName(value);
  };

  // Receive a response from iframe components
  useEffect(() => {
    if (selectedMasterComp != "") {
      const handleMessage = (event) => {
        //console.log("comp output:", event.data);
        if (event.data != null && event.data != undefined) {
          const compJson = { loadJson: event.data };
          setcompConfig(compJson);
          //message.success("Configuration Successfully Updated.");
          setIsPreviewModalVisible(false);
        }
      };

      window.addEventListener('message', handleMessage);
      return () => window.removeEventListener('message', handleMessage);
    }
  }, [selectedMasterComp]);

  const handleEdit = async (record, value) => {
    try {
      const data = await UpdateWorkflowComponent(record.key);
      const parsedConfig = JSON.parse(data.component.config);
      setEditRecordKey(record.key);
      setuserCompName(data.component.displayName);
      setselectedCategory(data.component.categoryId);
      setselectedMasterComp(data.component.componentId);
      setcompConfig(parsedConfig);
      setComponentData({
        categoryId: data.component.categoryId,
        categoryList: data.component.categoryName,
        master: data.component.componentId,
        componentName: data.component.displayName,
        componentConfig: data.component.config,

        projectPath: data.component.projectPath || '',
      });
      // Populate the form with the fetched data
      form.setFieldsValue({
        categoryId: data.component.categoryId,
        categoryList: data.component.categoryName,
        master: data.component.componentId,
        componentName: data.component.displayName,
        componentConfig: data.component.config,
        projectPath: data.component.projectPath || '',
      });


      // setselectedMasterComp(selectedMasterComp);

      setIsComponentVisible(true);
      setIsWorkflowCategoriesVisible(false);
      setIsComponentCreationVisible(true);
      setIsEditing(true);
      refreshWorkflowData();


    } catch (error) {
      console.error('Error fetching component data:', error);
    }
  };

  const handleEditCategory = async (record) => {
    try {
      const data = await UpdateWorkflowCategory(record.key);

      form.setFieldsValue({
        componentCategoryName: data.component.categoryName,
        categoryId: record.key,

      });

      setIsCategoryModalVisible(true);
      setIsComponentVisible(false);
      setIsWorkflowCategoriesVisible(true);
      setIsEditingCategory(true);
      refreshWorkflowData();
      refreshWorkflowData();
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };



  // Function to handle category deletion
  const handleCategoryDelete = async (record) => {
    try {
      const response = await DeleteWorkflowCategory(record.categoryId);
      debugger;
      if (response.success) {
        message.success('Category deleted successfully!');
        refreshWorkflowData();
      } else {
        message.error('Failed to delete category.');
      }
    } catch (error) {
      message.error('Error deleting category.');
    }
  };

  // Function to handle component deletion
  const handleComponentDelete = async (record) => {
    try {
      const response = await DeleteWorkflowComponent(record.key);
      if (response.success) {
        message.success('Component deleted successfully!');
        refreshWorkflowData();
      } else {
        message.error('Failed to delete component.');
      }
    } catch (error) {
      message.error('Error deleting component.');
    }
  };

  const handleBackClick = () => {
    setIsComponentVisible(false);
    setIsPreviewModalVisible(false);
    setIsWorkflowCategoriesVisible(true); // Show Workflow Categories
    setIsComponentCreationVisible(false); // Hide component creation form
    window.location.reload();
  };

  const menu = (
    <Menu onClick={handleAddNewClick}>
      <Menu.Item key="addCategory">Add Component Category</Menu.Item>
      <Menu.Item key="addComponent">Add Component</Menu.Item>
    </Menu>
  );

  //#region Hierarchy table
  // Transform data into parent and child arrays
  // Filter out parent categories without children or with null categoryId
  const parentData = Object.keys(workflowData)
    .filter(categoryName => !hideCategories.includes(categoryName))
    .map(categoryName => {
      const categoryItems = workflowData[categoryName];
      const categoryId = categoryItems.length > 0 ? categoryItems[0]?.categoryId || null : null;

      if (categoryId === null) {
        return null; // Skip this category if categoryId is null
      }

      return {
        key: categoryId,
        categoryname: categoryName,
        categoryId: categoryId,
      };
    })
    .filter(item => item !== null); // Remove null entries



  const childData = Object.keys(workflowData)
    .filter(categoryName => !hideCategories.includes(categoryName))
    .flatMap(categoryName => {
      const categoryItems = workflowData[categoryName];
      const categoryId = categoryItems.length > 0 ? categoryItems[0]?.categoryId || null : null;

      if (categoryId === null) {
        return []; // Skip this category if categoryId is null
      }

      return categoryItems.map(item => ({
        key: item.key,
        name: item.name,
        displayName: item.displayName,
        category: item.category,
        loc: item.loc,
        compId: item.compId,
        source: item.source,
        view: item.view,
        data: item.data,
        parentCategoryId: categoryId,
        parentCategoryname: categoryName,
      }));
    });

  // Define columns for the parent table
  const parentColumns = [
    {
      title: 'Category Name',
      dataIndex: 'categoryname',
      key: 'categoryname',
      sorter: (a, b) => a.categoryname.localeCompare(b.categoryname),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditCategory(record)}
            style={{ marginRight: 8 }}
          />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleCategoryDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    },
    {
      title: 'Category ID',
      dataIndex: 'categoryId',
      key: 'categoryId',
      hidden: 'true'
    },
  ];


  // Define columns for the child table
  const childColumns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      hidden: 'true'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      hidden: 'true'
    },
    {
      title: 'Component Name',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      hidden: 'true'
    },
    {
      title: 'Location',
      dataIndex: 'loc',
      key: 'loc',
      hidden: 'true'
    },
    {
      title: 'Component ID',
      dataIndex: 'compId',
      key: 'compId',
      hidden: 'true'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      hidden: 'true'
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      hidden: 'true'
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      hidden: 'true'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ marginRight: 8 }}
          >

          </Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleComponentDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    }
  ];

  const HierarchicalTable = () => {
    return (
      <Table
        className='cus-antd-white cus-table-medium'
        size='small'
        bordered={true}
        columns={parentColumns}
        expandable={{
          expandedRowRender: (record) => {
            const filteredChildData = childData.filter(item => item.parentCategoryname === record.categoryname);

            return (
              <Table
                size='small'
                columns={childColumns}
                dataSource={filteredChildData}
                pagination={false}
                rowKey="key"
                // className='cus-antd-white'
                bordered={true}
              />
            );
          },
        }}
        dataSource={parentData}
        rowKey="key"
        pagination={false}
      />
    );
  };

  //#endregion
  return (
    <div>
      <div  >
        <h2>Create Component</h2>
        <Dropdown overlay={menu}>
          <Button style={{ marginBottom: '10px' }} type="primary">Add New</Button>
        </Dropdown>
      </div>
      {(isComponentVisible || isPreviewModalVisible) && (
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={handleBackClick}
          style={{
            position: 'absolute',
            top: '160px',
            right: '70px',
          }}
        >
          Back
        </Button>
      )}
      <Modal
        title={isEditingCategory ? 'Edit Component Category' : 'Add Component Category'}
        open={isCategoryModalVisible}
        closable={true}
        footer={[
          <Button key="cancel" onClick={() => {
            setIsCategoryModalVisible(false);
            form.resetFields();
            setIsEditingCategory(false);
          }}>
            Cancel
          </Button>
        ]}
        onCancel={() => {
          setIsCategoryModalVisible(false);
          form.resetFields();
          setIsEditingCategory(false);
        }}
      >

        <Form form={form} layout="vertical">
          <Form.Item
            name="componentCategoryName"
            label="Component Category Name"
            rules={[{ required: true, message: 'Please input the category name!' }]}
          >
            <Input placeholder="Enter component category name" />
          </Form.Item>
          <Button type="primary" onClick={handleCategoryOk}>
            {isEditingCategory ? 'Update' : 'Add'}
          </Button>  <Form.Item
            name="categoryId" // Make sure this field is included
            noStyle // Hidden from user, just used for value
          >
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>


      {isWorkflowCategoriesVisible && <HierarchicalTable />}

      {isComponentCreationVisible && (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateComponent}
          style={{ marginTop: '20px' }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="categoryList"
                label="Category List"
                rules={[{ required: true, message: 'Please select a category!' }]}
              >
                <Select
                  placeholder="Select Category"
                  onChange={changeCategory}
                  value={selectedCategory}
                >
                  {categoryOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="master"
                label="Master"
                rules={[{ required: true, message: 'Please select a master!' }]}
              >
                <Select value={selectedMasterComp} placeholder="Select Master" onChange={changeMasterComp}>
                  {masterOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="componentName" label="Component Name">
                <Input onChange={e => setComponentName(e.target.value)} value={userCompName} />
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" htmlType="submit" onClick={handleConfigClick}>
            Config
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            style={{ marginLeft: '10px' }}
          >
            {isEditing ? 'Update' : 'Save'}
          </Button>
        </Form>
      )}

      <Modal
        title={`${componentData ? componentData.componentName : ''} Component `}
        visible={isPreviewModalVisible}
        footer={null}
        width="75%"
        onCancel={() => setIsPreviewModalVisible(false)}

      >
        {componentData && (
          <>
            <iframe
              src={componentData ? componentData.projectPath : ''}
              title="Component Preview"
              width="100%"
              height="500px"
              style={{ border: '2px solid #ccc' }}
            ></iframe>
          </>
        )}
      </Modal>


      {/* 
          <Collapse>
            {Object.keys(workflowData).map(category => (
              <Panel header={category} key={category}>
                <Table
                  dataSource={workflowData[category]}
                  columns={[
                    { title: 'ID', dataIndex: 'key', key: 'key', hidden: true },
                    { title: 'Component Name', dataIndex: 'name', key: 'name' },
                    { title: 'Display Name', dataIndex: 'displayName', key: 'displayName' },
                    {
                      title: 'Actions',
                      key: 'actions',
                      render: (_, record) => (
                        <span>
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(record)}
                            style={{ marginRight: 8 }}
                          >

                          </Button>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(record)}
                          >

                          </Button>
                        </span>
                      ),
                    }
                  ]}
                  rowKey="key"
                />
              </Panel>
            ))}
          </Collapse> */}

    </div >
  );
};

export default WorkFlowComponent;



